<template>
  <div class="mobile-container">
    <div class="page-header-container">
      <div class="search-container">
        <div class="search-item">
          <div class="icon img-cover" @click="handleSearch"><img src="../../assets/images/search-icon.png" /></div>
          <div class="input-item"><input ref="searchInput" v-model="searchKey" placeholder="输入要搜索的内容" type="search" @keyup.enter="handleSearch" /></div>
        </div>
        <router-link :to="{path: '/contact'}" class="kefu-item img-cover">
          <img src="../../assets/images/kefu-icon.png" />
        </router-link>
      </div>
      <div class="banner-container">
        <div class="banner-item">
          <van-swipe :autoplay="4500" indicator-color="white">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index">
              <div class="img img-cover" @click="bannerJump(item)">
                <img :src="item.Img" />
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
        <router-link v-if="ggList && ggList.length > 0" class="gg-item" :to="{ path: '/gg' }">
          <div class="icon img-cover"><img src="../../assets/images/gg-icon.png" /></div>
          <van-swipe class="detail-item" vertical :autoplay="5000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in ggList" :key="index" @click="handleShowGg(item)">
              <div class="item">{{ item.Title }}</div>
            </van-swipe-item>
          </van-swipe>
          <div class="right img-cover"><img src="../../assets/images/right-icon.png" /></div>
        </router-link>
      </div>
    </div>
    <div class="ability-list-item">
      <!-- <router-link class="item" :to="{path: '/watermark'}">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-1.png" /></div>
        <div class="name">去水印</div>
      </router-link> -->
      <div class="item" @click="handleWait">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-1.png" /></div>
        <div class="name">去水印</div>
      </div>
      <router-link class="item" :to="{path: '/vip'}">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-2.png" /></div>
        <div class="name">升级会员</div>
      </router-link>
      <router-link class="item" :to="{path: userInfo.FzLevel >= 50 ? '/website' : '/buildSite'}">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-3.png" /></div>
        <div class="name">分站搭建</div>
      </router-link>
      <router-link class="item" :to="{path: '/people'}">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-4.png" /></div>
        <div class="name">邀请好友</div>
      </router-link>
      <router-link v-if="showSstz" class="item" :to="{path: '/notice'}">
        <div class="icon img-contain"><img src="../../assets/images/home-nav-icon-5.png" /></div>
        <div class="name">消息通知</div>
      </router-link>
    </div>
    <div class="message-item">
      <div class="icon img-cover"><img src="../../assets/images/news-icon.png" /></div>
      <van-swipe class="detail-item" vertical :autoplay="4000" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in vipTipsList" :key="index">
          <div class="item">恭喜尾号{{ item.phone }}用户，成功晋升为{{ item.vip }}</div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="page-content-item">
      <div class="class-list-item" :class="classList.length > 1 ? 'long' : ''">
        <van-swipe indicator-color="#1399ff" :loop="false" show-indicators>
          <van-swipe-item v-for="(item, index) in classList" :key="index">
            <div class="class-list">
              <router-link class="class" v-for="(childItem, childIndex) in item" :key="childIndex" :to="{path: '/class', query: {id: childItem.Id}}">
                <div class="icon img-cover">
                  <img :src="childItem.CategoryThumb" />
                </div>
                <div class="name">{{ childItem.Name }}</div>
              </router-link>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <van-list v-model="loading" :finished="finish" @load="getGoodsList" loading-text="加载中" finished-text="" error-text="加载失败">
        <div v-if="finish && !loading && goodsList.length == 0" class="empty-item">
          <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
        </div>
        <div v-else class="goods-list">
          <template v-for="(item, index) in goodsList">
            <router-link v-if="item.IsClose == 2" :to="{path: '/goodsDetail', query: {id: item.Id}}" class="goods" :key="'2_' + index">
              <div class="img img-cover">
                <img v-if="item.GoodsThumb" :src="item.GoodsThumb" />
                <img v-else src="../../assets/images/no-image-icon.png" />
                <div class="count-bg"></div>
                <div class="count"><span>{{ item.BuyCount || 0 }}人已买</span></div>
              </div>
              <div class="name"><span v-if="item.Lable">{{ item.Lable }}</span> {{ item.GoodsName }}</div>
              <div class="money">¥ <span>{{ item.GoodsPrice }}</span></div>
              <div class="btn public-btn">立即购买</div>
            </router-link>
            <div v-else class="goods" :key="'1_' + index">
              <div class="img img-cover">
                <img v-if="item.GoodsThumb" :src="item.GoodsThumb" />
                <img v-else src="../../assets/images/no-image-icon.png" />
                <div class="count-bg"></div>
                <div class="count"><span>{{ item.BuyCount || 0 }}人已买</span></div>
              </div>
              <div class="name"><span v-if="item.Lable">{{ item.Lable }}</span> {{ item.GoodsName }}</div>
              <div class="money">¥ <span>{{ item.GoodsPrice }}</span></div>
              <div class="btn public-not-btn">暂停购买</div>
            </div>
          </template>
        </div>
      </van-list>
    </div>
    <mobileTabBar :tabBarKey="tabBarKey" />
    <mobilePopupComponent v-if="showMobilePopup" ref="mobilePopup" @onSure="mobilePopupOnSure" />
    <template v-for="(item, index) in ggList">
      <van-dialog v-if="item.IsTan == 1" :key="index" v-model="item.show" :show-cancel-button="false" :show-confirm-button="false">
        <div class="gg-popup-container">
          <div class="title-item img-cover">
            <img src="../../assets/images/pop-head-bg.png" />
            <div class="title">{{ item.Title }}</div>
          </div>
          <div class="content-item"><div v-html="item.Content"></div></div>
          <div class="btn-item">
            <div class="btn sure" @click="item.show = false">我知道了</div>
          </div>
        </div>
      </van-dialog>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileTabBar from '@/components/mobileTabBar'
import mobilePopupComponent from '@/components/mobilePopupComponent'

export default {
  name: "Home",
  components: {
    mobileTabBar,
    mobilePopupComponent
  },
  data() {
    return {
      tabBarKey: 'home',
      showMobilePopup: false,
      vipTipsList: [],
      bannerList: [],
      classList: [],
      goodsList: [],
      pageCount: 10,
      page: 1,
      finish: false,
      loading: false,
      searchKey: '',
      goodsName: '',
      showNoticRed: false,
      ggList: [],
      showSstz: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    searchKey(val){
      if (val == '' && this.goodsName != '') {
        this.goodsName = ''
        this.finish = false
        this.loading = false
        this.page = 1
        this.goodsList = []
        // this.getGoodsList()
      }
    }
  },
  mounted() {
    this.getBannerData()
    this.getClassList()
    this.handleSetVipTipsData()
    if (this.userInfo.Id) {
      this.handleShowNoticRed()
    }
    this.getGgList()
    this.handleGetSetting()
  },
  methods: {
    handleWait() {
      this.$dialog.alert({
        type: 'danger',
        title: '提示',
        message: '正在开发中，敬请期待',
        confirmButtonText: '确定',
      })
    },
    async handleGetSetting() {
      var res = await this.$API.getSet.post({
        'Key': 'cz_show'
      })
      const { userInfo } = this
      if (userInfo && userInfo.Id) {
        if (userInfo.UserIsSs == 1 && parseFloat(userInfo.AllMoney) >= parseFloat(res.info)) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      } else {
        if (res.info == -1) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      }
    },
    async handleSetVipTipsData() {
      var vips = await this.$API.vipMoney.post({
        Type: 1
      })
      const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      const list = []
      for (let i = 0; i < 50; i++) {
        list.push({
          phone: '' + this.getValue(nums) + this.getValue(nums) + this.getValue(nums) + this.getValue(nums),
          vip: this.getValue(vips)['Name']
        })
      }
      this.vipTipsList = list
    },
    getValue(data) {
      let random = Math.random()
      let num = Math.round(random * (data.length - 1))
      return data[num]
    },
    handleShowGg(data) {
      this.showMobilePopup = true
      this.$nextTick(() => {
        this.$refs.mobilePopup.open({
          title: data.Title,
          tips: '',
          content: data.Content,
          sureText: '我知道了',
          showCancel: false
        })
      })
    },
    mobilePopupOnSure() {
      this.showMobilePopup = false
    },
    async getGgList() {
      var res = await this.$API.announcedList.post();
      if (res && res.info && res.info.length > 0) {
        const data = []
        for (let index = 0; index < res.info.length; index++) {
          const item = res.info[index]
          data.push({
            ...item,
            show: true
          })
        }
        this.ggList = data
      }
    },
    async handleShowNoticRed() {
      var res = await this.$API.notifyRed.post()
      this.showNoticRed = res.info == 1
    },
    handleSearch() {
      if (this.searchKey) {
        this.goodsName = this.searchKey
        this.finish = false
        this.loading = false
        this.page = 1
        this.goodsList = []
        // this.getGoodsList()
        this.$refs.searchInput.blur()
      }
    },
    async getBannerData() {
      var res = await this.$API.bannerList.post();
      this.bannerList = res.info || []
    },
    async getClassList() {
      var res = await this.$API.goodsCategoryList.post();
      var list = []
      if (res.info && res.info.length > 0) {
        let i = -1
        for (let index = 0; index < res.info.length; index++) {
          const item = res.info[index]
          if (index % 10 == 0) {
            i += 1
            list[i] = []
          }
          list[i].push(item)
        }
      }
      this.classList = list
    },
    async getGoodsList() {
      const { userInfo } = this
      if (userInfo && userInfo.Id && userInfo.UserIsGoods != 1) {
        this.finish = true
        this.loading = false
        return
      }
      const { pageCount, page, goodsList, goodsName, websiteInfo } = this
      const params = {
        PageCount: pageCount, 
        Page: page,
        AdminId: websiteInfo.Id
      }
      if (goodsName) {
        params.GoodsName = goodsName
      } else {
        params.IsIndex = 1
      }
      var res = await this.$API.goodsList.post(params)
      this.goodsList = [
        ...goodsList,
        ...res.info
      ]
      this.page = page + 1
      this.finish = res.info.length < pageCount
      this.loading = false
    },
    async handleCollection(item, index) {
      var res = await this.$API.addGoodsCollect.post({
        GoodsId: item.Id
      })
      if (res.error == 0) {
        this.goodsList[index].IsCollect = item.IsCollect > 0 ? 0 : 1
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
      return false
    },
    bannerJump(item) {
      if (item.ParentId && item.Type == 1) {
        this.$router.push({
          path: '/goodsDetail',
          query: {
            id: item.ParentId
          }
        })
      }
      if (item.ParentId && item.Type == 2) {
        window.location.href = item.ParentId
      }
    }
  }
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
}
.page-header-container {
  width: 100%;
  height: 4.66rem;
  background: linear-gradient( 180deg, #1399ff 0%, rgba(19, 153, 255, 0) 100%);
  .search-container {
    padding: .38rem .32rem .4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-item {
      width: 6.02rem;
      height: .84rem;
      border-radius: .42rem;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: .32rem;
        height: .32rem;
        margin-left: .2rem;
      }
      .input-item {
        padding: 0 .2rem;
        flex: 1;
      }
    }
    .kefu-item  {
      width: .64rem;
      height: .64rem;
    }
  }
  .banner-container {
    /* height: 2.74rem; */
    margin: 0 .32rem;
    background-color: #fff;
    border-radius: .24rem;
    overflow: hidden;
    .banner-item {
      width: 6.86rem;
      height: 2rem;
      border-radius: .24rem;
      overflow: hidden;
      .img {
        width: 6.86rem;
        height: 2rem;
        border-radius: .24rem;
        overflow: hidden;
      }
      /deep/ .van-swipe__indicator {
        width: .1rem;
        height: .1rem;
        border-radius: 50%;
      }
    }
    .gg-item {
      padding: 0 .2rem;
      height: .74rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        width: 1.2rem;
      }
      .detail-item {
        flex: 1;
        height: .6rem;
        margin-left: .24rem;
        .item {
          height: .6rem;
          line-height: .6rem;
          text-align: right;
          font-size: .24rem;
          color: #b3b3b3;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .right {
        margin-left: .12rem;
        width: .24rem;
        height: .24rem;
      }
    }
  }
}
.ability-list-item {
  padding: .24rem .32rem;
  display: flex;
  align-items: center;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 1.2rem;
    height: 1.3rem;
    font-size: .24rem;
    color: #333;
    margin-right: .22rem;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .icon {
      width: .88rem;
      height: .88rem;
      overflow: hidden;
      border-radius: .24rem;
      background: rgba(245, 248, 250, 0.6);
      box-shadow: inset 0px 8px 12px 2px rgba(255, 255, 255, 0.16);
    }
    .name {
      line-height: .34rem;
    }
  }
}
.message-item {
  width: 6.86rem;
  height: .72rem;
  margin: 0 .32rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: .16rem;
  background-color: rgba(88, 183, 255, .16);
  .icon {
    width: .64rem;
    height: .64rem;
    margin-left: .08rem;
  }
  .detail-item {
    flex: 1;
    height: .72rem;
    color: #58b7ff;
    font-size: .24rem;
    margin: 0 .2rem;
    .item {
      width: 100%;
      height: .72rem;
      line-height: .72rem;
    }
  }
}
.page-content-item {
  margin-top: .24rem;
  background-color: #fff;
  border-radius: .24rem .24rem 0 0;
  .class-list-item {
    width: 100%;
    &.long {
      height: 3.38rem;
      .class-list {
        height: 2.6rem;
      }
    }
    .class-list {
      padding: .26rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .class {
        width: 20%;
        padding: .14rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .icon {
          width: .64rem;
          height: .64rem;
          border-radius: .12rem;
          overflow: hidden;
        }
        .name {
          line-height: .34rem;
          margin-top: .04rem;
          font-size: .24rem;
          color: #333;
        }
      }
    }
  }
  .goods-list {
    padding: 0 .3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .goods {
      width: 3.36rem;
      height: 5.96rem;
      border-radius: .16rem;
      overflow: hidden;
      margin-bottom: .24rem;
      .img {
        width: 3.36rem;
        height: 3.36rem;
        overflow: hidden;
        position: relative;
        .count-bg {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: .48rem;
          border-radius: .16rem .16rem 0 0;
          background-color: rgba(0, 0, 0, .5);
          filter: blur(.08rem);
        }
        .count {
          position: absolute;
          bottom: 0;
          left: .16rem;
          right: .16rem;
          line-height: .48rem;
          font-size: .24rem;
          color: #9cd5ff;
          text-align: right;
        }
      }
      .name {
        margin-top: .16rem;
        padding: 0 .16rem;
        line-height: .4rem;
        color: #333;
        font-size: .28rem;
        height: .8rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        span {
          height: .32rem;
          line-height: .32rem;
          padding: 0 .12rem;
          margin-right: .1rem;
          margin-top: .04rem;
          font-size: .2rem;
          color: #fff;
          border-radius: .04rem;
          background: linear-gradient( 315deg, #ffaa8a 0%, #ffde58 100%);
        }
      }
      .money {
        margin-top: .16rem;
        text-align: center;
        color: #ff2442;
        font-size: .24rem;
        line-height: .44rem;
        span {
          font-weight: bold;
          font-size: .36rem;
        }
      }
      .btn {
        margin: .16rem auto 0;
        height: .64rem;
        text-align: center;
        line-height: .64rem;
        font-size: .24rem;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}
.gg-popup-container {
  .title-item {
    width: 100%;
    height: 1.2rem;
    position: relative;
    .title {
      position: absolute;
      top: .48rem;
      width: 100%;
      text-align: center;
      line-height: .48rem;
      color: #333;
      font-size: .32rem;
      font-weight: bold;
    }
  }
  .content-item {
    margin: .24rem .32rem;
    min-height: 1.84rem;
    overflow-y: auto;
    line-height: .48rem;
    color: #333;
    font-size: .28rem;
  }
  .btn-item {
    padding: 0 .32rem .32rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      cursor: pointer;
      flex: 1;
      height: .8rem;
      color: #fff;
      text-align: center;
      line-height: .8rem;
      font-size: .28rem;
      font-weight: bold;
      border-radius: .12rem;
      background: linear-gradient( 135deg, #58b7ff 0%, #1399ff 100%);
    }
  }
}
</style>
